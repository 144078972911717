<template>
  <v-container>
    <h1> <textloader-component textKey="EngageraDigRubrik"/> </h1>
    <p> <textloader-component  textKey="EngageraDigBrödtext"/> </p>

    <v-img :src="require('@/assets/Aktuellt/EngageraDig/engagera_dig.jpg')"
    max-width="100%" class="center"
    ></v-img>
    <br>

  </v-container>
</template>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    TextloaderComponent
  }
}
</script>
