<template>
  <v-container>
    <h1><textloader-component textKey="StyrelsenRubrik" /></h1>
    <v-row>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/ordf.jpg"
          >
            <v-card-title>Ordförande</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Gustav Axelsson </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Föreningens ordförande är ytters ansvarig för föreningens
              verksamhet och arbete, och den främsta representanten för
              föreningen. Ordförande leder styrelsens och föreningens arbete
              tillsammans med vice ordförande och övriga styrelseledamöter.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:ordf@futf.se">ordf@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/vice.jpeg"
          >
            <v-card-title>Vice ordförande</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Albin Lindgren </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Föreningens vice ordförande tillika ekonomiskt ansvarig ansvarar
              för föreningens ekonomi och bokföring.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:vordf@futf.se">vordf@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/utb.jpeg"
          >
            <v-card-title>Utbildningsansvarig</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Ivan Noreland </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Utbildningsansvarig leder utbildningsutskottet i arbetet med
              studiebevakningen. Detta innebär att bedriva studiebevakning och
              att vara en frontfigur gällande utbildningsfrågor.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:utbildning@futf.se">utbildning@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/studiesoc.jpg"
          >
            <v-card-title>Studiesocialt ansvarig</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Ella Brottmann </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Föreningens Studiesocialt ansvarige ser till den fysiska och
              psykiska studiemiljön för studenter på programmet. Detta innebär
              även att vara huvudstuderandeskyddsombud på programmet och att
              tillsätta och koordinera andra skyddsombud, samt att vara en
              frontfigur för studiesociala ärenden. Studiesocialt ansvarig
              representerar även föreningen i frågor gällande
              internationalisering.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:soc@futf.se">soc@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/it.jpeg"
          >
            <v-card-title>IT-ansvarig</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Theodor Lindberg </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              IT-ansvarig är ansvarig för hemsida, sociala medier, e-post samt
              övrig informationsteknologi.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:it@futf.se">it@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/marknads.jpg"
          >
            <v-card-title>Marknadsföringsansvarig</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Astrid Eriksson </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Föreningens marknadsföringsansvarig är ansvarig för allt
              tryckmaterial som går via föreningen samt annan typ av
              marknadsföring. Det är marknadsföringsansvarige som ansvarar för
              att information når ut till föreningens medlemmar.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:marknadsforing@futf.se">marknadsforing@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/arb.jpg"
          >
            <v-card-title>Arbetsmarknadsansvarig</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0"> Felix Do </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Arbetsmarknadsansvarig är ansvarig för arbetsmarknadsutskottets
              verksamhet och arbete. Därmed är denne föreningens främsta ansikte
              mot arbetsmarknaden. Arbetsmarknadsansvarig är ansvarig för
              föreningens alumnverksamhet.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:arbetsmarknad@futf.se">arbetsmarknad@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="500px"
            src="@/assets/styrelsebilder/HT24/km.jpeg"
          >
            <v-card-title>Klubbmästare</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0">
            Kristin Herberthson & Elsa Enarsson
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <div><b>Ansvarsområde</b></div>

            <div>
              Föreningens klubbmästare är ansvarig för att se till att det
              anordnas sociala aktiviteter för sektionens studenter.
              Klubbmästaren arbetar för god kamratskap och bra sammanhållning
              mellan sektionens studenter.
            </div>
          </v-card-text>

          <v-card-actions>
            <v-icon color="#f1b434">mdi-email</v-icon>
            <a href="mailto:klubbmastare@futf.se">klubbmastare@futf.se</a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mx-auto" max-width="400">
      <v-img
        class="white--text align-end"
        height="500px"
        src="@/assets/styrelsebilder/HT24/idrott.jpeg"
      >
        <v-card-title>Idrottsansvarig</v-card-title>
      </v-img>

      <v-card-subtitle class="pb-0"> Rebecka Hemlin </v-card-subtitle>

      <v-card-text class="text--primary">
        <div><b>Ansvarsområde</b></div>

        <div>
          Idrottsansvarige är ytterst ansvarig för att anordna föreningens
          idrottsaktiviteter och uppmuntra programmets studenter att närvara på
          dessa.
        </div>
      </v-card-text>

      <v-card-actions>
        <v-icon color="#f1b434">mdi-email</v-icon>
        <a href="mailto:idrott@futf.se">idrott@futf.se</a>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    TextloaderComponent
  }
}
</script>
