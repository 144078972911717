<template>
  <v-container>
    <body>
      <h1>Frågor att besvara</h1>
        <v-card v-for="item in unanswered" :key="item.id">
        <p class='pl-4 pt-4'> Fråga: {{ item.question_txt }} </p>
        <v-textarea
        v-model="item.answer"
        counter="100"
        auto-grow
        rows="1"
        class="ma-4"
        ></v-textarea>
        <v-btn @click="answerQuestion(item.answer, item.id)"> Submit! </v-btn>
        <v-btn class='ma-4' color='red' @click='removeQuestion(item.id)'> Ta bort </v-btn>
        </v-card>
      <h1>Besvarade frågor</h1>
        <v-card v-for="item in answered" :key="item.id">
        <p class='pl-4 pt-4'> Fråga: {{ item.question_txt }} </p>
        <p class="pl-4 font-weight-bold"> Svar: {{ item.answer }} </p>
        <v-btn class='pa-4' color='red' @click='removeQuestion(item.id)'> Remove </v-btn>
      </v-card>
    </body>
  </v-container>
</template>

<script>
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

export default {
  data: () => ({
    question: undefined,
    answered: [],
    unanswered: [],
    answerText: undefined
  }),
  mounted: function () {
    axios.get('/api/questions/all', getAuthHeader())
      .then((result) => {
        this.question = result.data
        this.question.forEach(element => {
          if (element.is_answered) {
            this.answered.push(element)
          } else {
            this.unanswered.push(element)
          }
        })
        console.log(this.questionObject)
      })
  },
  methods: {
    removeQuestion: function (id) {
      axios.delete('api/questions/remove', { data: { id: id }, headers: getAuthHeader().headers })
        .then((res) => {
          console.log(res)
          window.location.reload()
        })
    },
    answerQuestion: function (answer, id) {
      console.log(answer, id)
      axios.put('api/questions/answerQuestion', { id: id, answer: answer }, getAuthHeader())
        .then((res) => {
          window.location.reload()
        })
    }
  }
}
</script>
